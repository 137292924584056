'use client'

import { FormObjectView, key } from 'utility/utility'
import { ComponentFormSelectFieldFragment } from 'generated/graphql'
import { ComponentFormSelectOption } from 'components/form/ComponentFormSelectOption/ComponentFormSelectOption'
import { useFormContext } from 'react-hook-form'

export const ComponentFormSelectField: FormObjectView<ComponentFormSelectFieldFragment> =
    ({
        name,
        label,
        required,
        options = [],
        ...object
    }) => {

        const { register, formState: { errors } } = useFormContext()

        return (
            <>
                <label>
                    <select
                        id={key(object)}
                        {...register(name.replace(/[^a-zA-Z0-9]/g, ''), {
                            required
                        })}
                    >
                        {
                            options.map(option =>
                                <ComponentFormSelectOption key={option.value} {...option} />
                            )
                        }
                    </select>
                    <span>{label}</span>
                </label>
                {
                    !required || errors[name] === undefined ? <></> :
                        <span>This field is required</span>
                }
            </>
        )

    }
