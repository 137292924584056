'use client'

import { FormObjectView, key } from 'utility/utility'
import { ComponentFormTelephoneNumberFieldFragment } from 'generated/graphql'
import { useFormContext } from 'react-hook-form'

export const ComponentFormTelephoneNumberField: FormObjectView<ComponentFormTelephoneNumberFieldFragment> =
    ({
        name,
        label,
        placeholder,
        required,
        ...object
    }) => {

        const { register, formState: { errors } } = useFormContext()

        return (
            <>
                <label htmlFor={key(object)}>
                    <input
                        type='tel'
                        id={key(object)}
                        placeholder={placeholder ?? label}
                        {...register(name.replace(/[^a-zA-Z0-9]/g, ''), {
                            pattern: {
                                value: /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
                                message: 'Please enter a valid telephone number'
                            },
                            required: 'This field is required',
                            setValueAs: (v: string): string => v.trim()
                        })}
                    />
                    <span>{label}</span>
                </label>
                {
                    !required || errors[name] === undefined ? <></> :
                        <span>{ ( errors[name]?.message as string | undefined ) ?? 'Please enter a valid value' }</span>
                }
            </>
        )

    }

